import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import Page from "../../Components/Page";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import {SELECT_COLUMN} from "../../Components/ReactTable/CheckboxColumnComponent";
import ReactTable from "../../Components/ReactTable";
import IconImage, {Icons} from "../../Components/IconImage";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import {toastError} from "../../../utils/toast";
import {toast} from "react-toastify";
import {useRedirectMessage} from "../../../utils/redirectMessage";

const CellsToStores = () => {
    const params = useParams()
    const cellId = parseInt(params.cellId)

    useRedirectMessage();

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToStores`;

    const [cellsToStores, setCellsToStores] = useState([])
    const [loading, setLoading] = useState(false)
    const [unassignStoresModalVisible, setUnassignStoresModalVisible] = useState(false)


    const loadCellsToStores = () => {
        setLoading(true);
        makeApiCall(endpointUrl)
            .then(data => {
                const results = data.data.filter(cell => cell.cell_id === cellId)
                setCellsToStores(results)
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadCellsToStores()
    }, []);

    const saveUnassignStoresAndRefresh = (selectedRows) => {
        setLoading(true)
        setUnassignStoresModalVisible(false)
        return makeApiCallPost(
            `${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToStores/unassignStores`,
            {
                cellId: cellId,
                storeIds: selectedRows.map(row => row.original.store_id),
            }
        ).then((response) => {
            if (response?.apiError) {
                console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                toastError(toast, `Issue unassigning stores`)
                setLoading(false);
            }
        }).then(() => loadCellsToStores())
    }

    return (<Page beta name={"Cell To Stores"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Services"},
                      {label: "Cells", to: "/cellsService"},
                      {label: `${cellId}`}]}
                  />}
    >
        <LoadingWrapper loading={loading}>
            <ReactTable
                filterable
                data={cellsToStores}
                columns={[
                    SELECT_COLUMN,
                    {header: "Cell Id", accessorKey: "cell_id"},
                    {header: "description", accessorKey: "cell_description"},
                    {header: "Store Id", accessorKey: "store_id"},
                    {header: "Store Name", accessorKey: "store_name"},
                ]
                }
                initialState={{pagination: {pageSize: 25}}}
            >
                {
                    ({table}) => {
                        const selectedRows = table.getSelectedRowModel().rows;
                        return (
                            <span className={'designation-action-buttons-container'}
                                  data-testid={"stores-action-button-container"}>
                                 <a type={"button"} className={"button primary"}
                                    href={`/cellsToStores/assignStore/${cellId}`}>Assign Store</a>
                                 <div className="designation-header-component-divider"/>
                                 <button type={'button'} aria-label={'un-assign'}
                                         onClick={() => setUnassignStoresModalVisible(true)}
                                         className={"designation-action-button"}
                                         disabled={!selectedRows?.length}>
                                     <IconImage iconType={Icons.UN_ASSIGN}/>
                                 </button>
                                {unassignStoresModalVisible && <ConfirmationModal
                                    confirmButtonClass={"danger-button"}
                                    confirmButtonText={"Unassign"}
                                    headerText={`Unassign ${selectedRows.length === 1 ? 'Store' : 'Stores'}`}
                                    cancellationCallback={() => setUnassignStoresModalVisible(false)}
                                    confirmationCallback={() => saveUnassignStoresAndRefresh(selectedRows)}>
                                    {`Unassign ${selectedRows.length} ${selectedRows.length === 1 ? 'store' : 'stores'} from`}&nbsp;
                                    <b>{`${cellId}`}</b>
                                </ConfirmationModal>}
                            </span>
                        )
                    }
                }
            </ReactTable>
        </LoadingWrapper>
    </Page>);
};

export default CellsToStores;